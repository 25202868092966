import useUserValidationRules from './useUserValidationRules'

/**
 *
 */
export default function () {
  const {
    fio,
    familyName,
    birthDay,
    passport,
    email,
    serverFormatDate
  } = useUserValidationRules()

  return {
    passport,
    fio,
    familyName,
    birthDay,
    email,
    serverFormatDate
  }
}
