
// @ts-nocheck
import { nextTick } from 'vue'
import type { Component, Ref } from 'vue'
import type { Validation } from '@vuelidate/core'
export const sendFnc = async (v$: Ref<Validation>, formEl: HTMLFormElement, inputRefs: Array<Component | HTMLElement>) => {
  v$.value.$touch()
  if (v$.value.$errors.length > 0) {
    // console.log(v$.value.$errors[0].$property, inputRefs.map(el => el.$el))
    await nextTick()
    const formWrapper = formEl.closest('.--enable-scroll') || window

    let element: HTMLElement

    for (const inputRef of inputRefs) {
      // console.log(inputRef.$props.id, v$.value.$errors[0].$property)
      if (inputRef.$props) {
        if (inputRef.$props.id === v$.value.$errors[0].$property) {
          element = inputRef.$el
          break
        }
      } else if (inputRef.id === v$.value.$errors[0].$property) {
        element = inputRef
        break
      }
    }

    const inputElRect = element.getBoundingClientRect()
    const offset = 10

    formWrapper.scrollBy({
      top: inputElRect.top - inputElRect.height + offset,
      behavior: 'smooth'
    })

    return false
  }
  return true
}

const scrollToError = (component) => {
  if (component._props?.isError) {
    (component.$el as HTMLElement).scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    })
    return true
  }

  if (!component.$children) { return }

  for (const child of component.$children) {
    if (scrollToError(child)) { return true }
  }
}

export const sendFncClean = async (v$: Ref<Validation>, component) => {
  v$.value.$touch()
  await nextTick()

  if (!(v$.value.$errors.length > 0)) { return true }

  scrollToError(component)

  return false
}
