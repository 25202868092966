import { computed, ref } from 'vue'
import BaseMaskedInput from './BaseMaskedInput.vue'


export default {
  __name: 'BaseInput',
  props: {
  id: {
    default: '',
    type: String
  },
  label: {
    default: '',
    type: String
  },
  name: {
    type: String,
    required: false
  },
  fieldSizing: {
    type: String,
    required: false,
    default: 'content'
  },
  mask: {
    default: '',
    type: [String, Array]
  },
  placeholder: {
    default: '',
    type: String
  },
  type: {
    default: 'text',
    type: String
  },
  inputmode: {
    default: '',
    type: String
  },
  value: {
    type: [String, Number],
    default: ''
  },
  errorMessage: {
    type: [String, Number],
    default: 'Данные введены неверно'
  },
  isError: {
    type: Boolean,
    default: false
  },
  isRow: {
    type: Boolean,
    default: false
  },
  autocomplete: {
    type: String,
    default: 'off'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  masked: {
    type: Boolean,
    default: false
  },
  isCapitalize: {
    type: Boolean,
    default: false
  },
  textarea: {
    type: Boolean,
    default: false
  },
  maxSymbols: {
    type: Number,
    default: -1
  },
  noBorder: {
    type: Boolean,
    default: false
  },
  maskConfig: {
    type: Object,
    default: () => ({})
  },
  keyValidators: {
    type: Array,
    default: () => []
  }
},
  emits: [
  'update:value',
  'input',
  'blur',
  'focus'
],
  setup(__props, { emit }) {

const props = __props;





const updateValue = (value) => {
  emit('update:value', value)
  emit('input', value)
}

const listeners = computed(() => {
  return {
    input: (event) => {
      if (event.type === 'input') {
        return updateValue(event.target.value)
      } else {
        return updateValue(event)
      }
    },
    blur: (e) => {
      emit('blur', e.target.value.trim())
    },
    focus: (e) => {
      emit('focus', e)
    },
    // keydown: (event) => {

    // },
    textInput: (event) => {
      if (props.keyValidators.length < 1) { return event }

      for (const fnc of props.keyValidators) {
        if (!fnc(event)) {
          event.preventDefault()

          return event
        }
      }

      return event
    }
  }
})

const focused = ref(false)


return { __sfc: true,props, emit, updateValue, listeners, focused, BaseMaskedInput }
}

}