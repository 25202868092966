
import { defineComponent } from 'vue'
import { mask } from 'maska'

export default defineComponent({
  name: 'BaseMaskedInput',
  props: {
    value: [String, Number],
    mask: {
      type: [String, Array],
      required: true
    },
    masked: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    tokens: {
      type: Object,
      default: () => ({})
    },
    maskConfig: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['input', 'update:modelValue', 'blur', 'focus'],
  data () {
    return {
      lastValue: null,
      display: this.value
    }
  },
  computed: {
    config () {
      return {
        mask: this.mask,
        tokens: Object.assign(
          this.tokens,
          {
            '#': { pattern: /\d/ },
            p: { pattern: /\d|\// },
            F: {
              pattern: /[0-9АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
              transform: v => v.toUpperCase()
            },
            f: {
              pattern: /[АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
              transform: v => v.toUpperCase()
            },
            A: {
              pattern: /[0-9ABCDEFGHJKLMNPRSTUVWXYZabcdefghjklmnprstuvwxyz]/,
              transform: v => v.toUpperCase()
            }
          },
          this.tokens
        ),
        masked: this.masked,
        ...this.$props.maskConfig
      }
    }
  },
  watch: {
    value (newValue) {
      if (newValue !== this.lastValue) {
        this.display = newValue
      }
    },
    masked () {
      this.refresh(this.display)
    }
  },
  methods: {
    onInput (evt) {
      if (evt.isTrusted || !evt.inputType) { return }
      this.refresh(evt.target.value)
    },
    refresh (val) {
      this.display = val
      const value = this.config.noManual ? val : mask(val, this.config.mask, this.config.tokens, this.config.masked)
      if (value !== this.lastValue) {
        this.lastValue = value
        this.$emit('input', value)
        this.$emit('update:modelValue', value)
      }
    }
  }
})
