/**
 *
 */
export default function () {
  /** user validation */

  const familyName = () => {
    return {
      validName: (val: string) => {
        return !val || /^[а-яёА-ЯË-]+(?: [.а-яёА-ЯË-]+)+$/.test(val)
      }
    }
  }

  const fio = () => {
    return {
      validFio: (val: string) => {
        return !val || /^[а-яёА-ЯË-]+(?: [.а-яёА-ЯË-]+)+(?: [.а-яёА-ЯË-]+)+$/.test(val)
      }
    }
  }

  const birthDay = () => {
    return {
      validBd: (val: string) => {
        return !val || /^(?:0[1-9]|[12][0-9]|3[01]).(?:0[1-9]|1[012]).(?:19|20)[0-9]{2}$/.test(val)
      }
    }
  }

  const serverFormatDate = () => {
    return {
      validDate: (val: string) => {
        return !val || /^(?:19|20)[0-9]{2}-(?:0[1-9]|1[012])-(?:0[1-9]|[12][0-9]|3[01])$/.test(val)
      }
    }
  }

  const email = () => {
    return {
      validEmail: (val: string) => {
        return !val || /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(val)
      }
    }
  }

  const passport = () => {
    return {
      validPassport: (val: string) => {
        return !val || /\d{10}/.test(val)
      }
    }
  }
  return {
    familyName,
    fio,
    birthDay,
    email,
    passport,
    serverFormatDate
  }
}
